import React, { useMemo, useState } from 'react';

import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, Table, useMessage, useSafeState } from '@aprioritechnologies/core';
import { DataRequest, DataRequestBuilder } from '@aprioritechnologies/data-source-models';
import { LayoutType, SourceList } from '@aprioritechnologies/data-source-react';
import { ConfirmDialog } from '@aprioritechnologies/labs';
import { useCurrentUser } from '@aprioritechnologies/user';

import { Feature } from '../../../constants/feature';
import { asData } from '../../../hooks/use-loadable-data';
import { User, UserBuilder } from '../../../model/user';
import { useCustomer } from '../../../services/customer.service';
import { useFeature } from '../../../services/feature/use-feature-flag-service';
import { useUserDataSource, useUserService } from '../../../services/user-service';
import { UserManagementWizard } from '../user-management-wizard/user-management-wizard';

import { useUserManagementListColumns } from './user-management-list-columns';
import { StyledUserManagementList } from './user-management-list.styles';

export type UserManagementId =
  'user-management.actions.delete.confirm' |
  'user-management.actions.delete.confirm.no' |
  'user-management.actions.delete.confirm.yes' |
  'user-management.button.add-user' |
  'user-management.columns.username' |
  'user-management.columns.email' |
  'user-management.columns.assigned-role' |
  'user-management.columns.job-title' |
  'user-management.columns.family-name' |
  'user-management.columns.given-name' |
  'user-management.columns.status' |
  'user-management.columns.created-at' |
  'user-management.columns.updated-at' |
  'user-management.columns.actions' |
  'user-management.columns.actions.edit' |
  'user-management.columns.actions.delete' |
  'user-management.status.active' |
  'user-management.status.inactive' |
  'user-management.title';

export const UserManagementList = () => {
  const [userDialogOpen, setUserDialogOpen] = useSafeState(false);
  const [userRemoveConfirm, setUserRemoveConfirm] = useSafeState(false);
  const [removing, setRemoving] = useSafeState(false);
  const [targetUser, setTargetUser] = useSafeState(new UserBuilder().build());
  const { currentUser } = useCurrentUser();
  const [customer] = useCustomer(asData(currentUser)?.customerIdentity);
  const [request, setRequest] = useState<DataRequest>(new DataRequestBuilder().build());
  const userService = useUserService();
  const source = useUserDataSource();
  const deleteFeature = useFeature(Feature.UserManagementDelete);

  type T = UserManagementId;
  const addUserButtonLabel = useMessage<T>('user-management.button.add-user');
  const userManagementTitle = useMessage<T>('user-management.title');
  const removeUserConfirmYes = useMessage<T>('user-management.actions.delete.confirm.yes');
  const removeUserConfirmNo = useMessage<T>('user-management.actions.delete.confirm.no');
  const removeUserConfirm = useMessage<T>('user-management.actions.delete.confirm', {
    familyName: targetUser?.userProfile?.familyName,
    givenName: targetUser?.userProfile?.givenName
  });

  const _edit = async (user: User) => {
    setTargetUser(user);
    setUserDialogOpen(true);
  };

  const closeEditWizard = () => {
    setUserDialogOpen(false);
    setTargetUser(new UserBuilder().build());
  };

  const edit = asData(customer)?.isSamlManaged === false
    ? _edit
    : undefined;

  const _remove = (user: User) => {
    setTargetUser(user);
    setUserRemoveConfirm(true);
  };

  const remove = deleteFeature
    ? _remove
    : undefined;

  const closeRemoveConfirmation = () => {
    setUserRemoveConfirm(false);
    setTargetUser(new UserBuilder().build());
  };

  const removeTargetUser = async () => {
    try {
      setRemoving(true);
      await userService.delete(targetUser!.identity);
      setRequest((r) => new DataRequestBuilder().copy(r).build());
    } finally {
      setRemoving(false);
      closeRemoveConfirmation();
    }
  };

  const operations = useMemo(() => ({ edit, remove }), [edit, remove]);
  const columns = useUserManagementListColumns(operations);

  const renderNewUserButton = () => {
    if (!edit) {
      return null;
    }

    return (
      <Button
        className='add-user-button'
        color='inherit'
        variant='contained'
        onClick={() => edit(new UserBuilder().build())}
      >
        <FontAwesomeIcon icon={faPlus} className='mr-2' />
        {addUserButtonLabel}
      </Button>
    );
  };

  return (
    <StyledUserManagementList className='user-management-list'>
      <UserManagementWizard
        dialogOpen={userDialogOpen}
        closeModal={closeEditWizard}
        onSave={() => {
          closeEditWizard();
          setRequest((r) => new DataRequestBuilder().copy(r).build());
        }}
        user={targetUser}
      />
      <SourceList
        className='user-management-list h-100'
        source={source}
        title={userManagementTitle}
        hideAudit
        hideLayout
        initialLayout={LayoutType.TABLE_LAYOUT}
        rightToolbar={renderNewUserButton()}
        request={request}
        onRequestChange={setRequest}
        renderTable={(options) => (
          <Table
            {...options}
            columns={columns}
            identifierKey='identity'
          />
        )}
      />
      <ConfirmDialog
        className='user-management-list-confirm-remove'
        open={userRemoveConfirm}
        question={removeUserConfirm}
        onPositive={removeTargetUser}
        onNegative={closeRemoveConfirmation}
        positiveButtonProps={{
          disabled: removing,
          text: removeUserConfirmYes
        }}
        negativeButtonProps={{
          text: removeUserConfirmNo
        }}
      />
    </StyledUserManagementList>
  );
};
