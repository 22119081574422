import React, { useCallback, useEffect, useRef } from 'react';

import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router';

import {
  Box,
  Button,
  EnvironmentHelpers,
  Loader,
  LoggingHelpers,
  ROUTE_HOME,
  Text,
  useMessageFormatter
} from '@aprioritechnologies/core';

import useFullHeightOffset from '../../../hooks/use-full-height-offset';
import { dataHasErrored, dataIsLoading } from '../../../hooks/use-loadable-data';
import {
  CookielessEmbedSession,
  useUserActivityCookielessEmbedSession,
  useUserActivityService
} from '../../../services/user-activity.service';

import '@fontsource/roboto/500.css';

/**
 * The state object containing mutable looker values.
 * This represents a reference object that our
 * `window.postMessage` event callback will use.
 */
interface LookerEmbedState {
  connected: boolean;
  iframeElm: HTMLIFrameElement | null;
  session: CookielessEmbedSession | Error | Symbol;
}

const LOOKER_HOST =
  EnvironmentHelpers.getEnvironmentVariable('REACT_APP_LOOKER_HOST');
const LOOKER_DASHBOARD_ID =
  EnvironmentHelpers.getEnvironmentVariable('REACT_APP_LOOKER_DASHBOARD_ID');
const CUSTOMER_SUPPORT_LINK =
  EnvironmentHelpers.getEnvironmentVariable('REACT_APP_CUSTOMER_SUPPORT_LINK');

export type UserActivityViewMessageId =
  'user-activity-view.error-display.home-button' |
  'user-activity-view.error-display.retry-button' |
  'user-activity-view.error-display.message' |
  'user-activity-view.error-display.support-link-text' |
  'user-activity-view.error-display.title';

const UserActivityView = () => {

  const history = useHistory();
  const formatMessage = useMessageFormatter();

  const userActivityService = useUserActivityService();
  const [ embedSession, refreshEmbedSession ] = useUserActivityCookielessEmbedSession();
  const [ ref, height ] = useFullHeightOffset();

  const getIframeSrc = useCallback((session: CookielessEmbedSession) => {
    const { authenticationToken, navigationToken } = session;
    const embedDomain = window.location.origin;

    // We need to encode the embed dashboard path and search properties.
    const embedUrl = `/embed/dashboards/${LOOKER_DASHBOARD_ID}` +
      `?embed_domain=${embedDomain}` +
      `&embed_navigation_token=${navigationToken}`;
    const targetUri = encodeURIComponent(embedUrl);

    return `${LOOKER_HOST}/login/embed/${targetUri}?embed_authentication_token=${authenticationToken}`;
  }, [embedSession]);

  // This provides a reference to an object that
  // can be used inside our `window.postMessage` event handler.
  // This way we only need to create the event once
  // with a single reference to objects that we can mutate within the react scope.
  const lookerStateRef = useRef<LookerEmbedState>({
    connected: false,
    iframeElm: null,
    session: embedSession
  });

  /**
   * Ensure the looker state gets updated when the session changes.
   */
  useEffect(() => {
    lookerStateRef.current.session = embedSession;
  }, [
    embedSession
  ]);

  /**
   * Sets up an event listener to communicate with looker via the iframe.
   * Looker communicates with us by sending events via `window.postMessage`.
   * We communicate with looker by sending events via `iframe.contentWindow.postMessage`.
   */
  useEffect(() => {

    const eventListener = async (event: MessageEvent) => {

      const { connected, iframeElm, session } = lookerStateRef.current;

      if (event.origin !== LOOKER_HOST) {
        return;
      }

      if (!iframeElm || dataIsLoading(session) || dataHasErrored(session)) {
        return;
      }

      const data = JSON.parse(event.data);
      const { contentWindow } = iframeElm;

      if (!contentWindow) {
        LoggingHelpers.warn(
          'The content window was not set. ' +
            `Unable to process lookers '${data.type}' event`
        );
        return;
      }

      if (data.type === 'session:tokens:request') {

        if (!connected) {
          lookerStateRef.current.connected = true;
          const connected = {
            type: 'session:tokens',
            ...mapSessionToLookerKeys(session)
          };
          contentWindow.postMessage(
            JSON.stringify(connected),
            LOOKER_HOST
          );
        } else {
          lookerStateRef.current.connected = false;
          const tokens = await userActivityService.generateCookielessEmbedTokens(
            session.sessionReferenceToken,
            session.apiToken,
            session.navigationToken
          );
          const lookerTokensMessage = {
            type: 'session:tokens',
            ...mapSessionToLookerKeys(tokens)
          };
          contentWindow.postMessage(
            JSON.stringify(lookerTokensMessage),
            LOOKER_HOST
          );
        }
      }
    };

    window.addEventListener('message', eventListener);

    return () => {
      window.removeEventListener('message', eventListener);
    };
  }, [ ]);

  /**
   * Looker expects the session properties in snake_case.
   */
  const mapSessionToLookerKeys = (session: CookielessEmbedSession): any => {
    const achToLookerMap: any = {
      authenticationToken: 'authentication_token',
      authenticationTokenTtl: 'authentication_token_ttl',
      navigationToken: 'navigation_token',
      navigationTokenTtl: 'navigation_token_ttl',
      apiToken: 'api_token',
      apiTokenTtl: 'api_token_ttl',
      sessionReferenceToken: 'session_reference_token',
      sessionReferenceTokenTtl: 'session_reference_token_ttl'
    };
    const mappedRecord = {} as any;
    Object.keys(session).forEach(key => {
      mappedRecord[achToLookerMap[key]] = (session as any)[key];
    });
    return mappedRecord;
  };

  const renderIframe = () => {

    if (dataIsLoading(embedSession)) {
      return <Loader isFullScreen />;
    };

    if (dataHasErrored(embedSession)) {
      return (
        <Box
          id='user-activity-events-error-display'
          alignItems='center'
          className='user-activity-events-error-display'
          data-testid='user-activity-events-error-display'
          display='flex'
          flexDirection='column'
          height='100%'
          justifyContent='center'
          width='100%'
        >
          <Box
            alignItems='center'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            style={{ textAlign: 'center' }}
            width='480px'
          >
            <FontAwesomeIcon icon={faXmarkCircle} style={{ fontSize: '120' }} />
            <Text.D1 marginTop={3}>
              {formatMessage('user-activity-view.error-display.title')}
            </Text.D1>
            <Text.Body1 marginTop={3}>
              {`${formatMessage('user-activity-view.error-display.message')} `}
              <a href={CUSTOMER_SUPPORT_LINK} rel='noreferrer noopener' target='_blank'>
                {formatMessage('user-activity-view.error-display.support-link-text')}
              </a>.
            </Text.Body1>
            <Box
              alignItems='center'
              display='flex'
              justifyContent='center'
              marginTop={8}
              width='100%'
            >
              <Button
                size='large'
                type='button'
                onClick={() => refreshEmbedSession()}
                marginRight={1}
                variant='outlined'
              >
                {formatMessage('user-activity-view.error-display.retry-button')}
              </Button>
              <Button
                size='large'
                type='button'
                onClick={() => history.push(ROUTE_HOME)}
                variant='contained'
              >
                {formatMessage('user-activity-view.error-display.home-button')}
              </Button>
            </Box>
          </Box>
        </Box>
      );
    };

    return (
      <iframe
        id='user-activity-events-iframe'
        ref={(ref) => {
          lookerStateRef.current.iframeElm = ref;
        }}
        data-testid='user-activity-events-iframe'
        src={getIframeSrc(embedSession)}
        title='User Activity Events'
        height='100%'
        width='100%'
        frameBorder={0}
        marginHeight={0}
        marginWidth={0}
      />
    );
  };

  return (
    <Box
      className='user-activity-view'
      ref={ref}
      height={height}
      width='100%'
    >
      {renderIframe()}
    </Box>
  );
};

export default UserActivityView;
